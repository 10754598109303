import { graphql, useStaticQuery } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import { Box } from 'juhuui';
import React, { memo, useMemo } from 'react';
import shallow from 'zustand/shallow';

import Therapist, { TherapistType } from '../components/therapist';
import { HeadingSm } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { useStore } from '../zustand';

interface TherapistsProps {
  therapists: TherapistType[];
  portrait: boolean;
}

const Therapists = memo(
  ({ therapists: therapistsIn, portrait = true }: TherapistsProps) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    const data = useStaticQuery(query);
    const { therapists, praxi } = data.strapi || {};
    const { therapistTitle } = getLocalizationObj(praxi, language) || {};

    // set overlay content
    const [setModalLevel2Visible, setModalLevel1Data, setModalLevel2Data] =
      useStore(
        (state) => [
          state.setModalLevel2Visible,
          state.setModalLevel1Data,
          state.setModalLevel2Data,
        ],
        shallow
      );

    const modalLevel2Visible = useStore((state) => state.modalLevel2Visible);

    const changeModal = ({ id, ...rest }) => {
      setModalLevel2Visible();

      modalLevel2Visible
        ? setModalLevel1Data({ name: 'team', id, ...rest })
        : setModalLevel2Data({ name: 'team', id, ...rest });
    };

    const filteredTherapists = useMemo(() => {
      return therapistsIn
        ? therapists
            .filter((therapist: TherapistType) => {
              if (!therapistsIn) return false;

              const localeTherapist = getLocalizationObj(therapist, language);

              if (!localeTherapist) {
                return false;
              }

              return (
                therapistsIn.findIndex((therapistIn) => {
                  return localeTherapist.id === therapistIn.id;
                }) !== -1
              );
            })
            .sort((first: any, second: any) => first.order - second.order)
            .map((therapistIn: TherapistType) => {
              if (!getLocalizationObj(therapistIn, language)) return null;
              const therapist = getLocalizationObj(therapistIn, language);

              return (
                <Therapist
                  key={therapist.surname}
                  // pricing
                  cursor="pointer"
                  item={therapist}
                  portrait={portrait}
                  srcsetWidthDesktop="20vw"
                  srcsetWidthMobile="48vw"
                  onClick={() => changeModal(therapist)}
                />
              );
            })
        : [];
    }, [therapistsIn]);

    if (filteredTherapists.length === 0) return null;

    return (
      <Box
        className="therapists"
        css={{
          column: ['1/span 12', undefined, '8/span 2'],
          pt: ['16', undefined, '0px'],
          pb: [6, undefined, 6],
        }}
      >
        <HeadingSm
          as="h3"
          classNames="pb-3"
          // css={{ pb: [3] }}
        >
          {therapistTitle}
        </HeadingSm>
        <Box
          css={{
            d: ['grid', undefined, 'block'],
            templateColumns: 'repeat(2, minmax(0, 1fr))',
            gridAutoFlow: 'row',
            gap: '0 var(--gutter-width)',
          }}
        >
          {filteredTherapists}
        </Box>
      </Box>
    );
  }
);

const query = graphql`
  query TherapistsQuery {
    strapi {
      praxi {
        localizations {
          therapistTitle
        }
        therapistTitle
      }
      therapists {
        localizations {
          order
          id
          diseases {
            title
          }
          image {
            url
            file {
              ...Image
            }
          }
          forename
          surname
          title
          pricings {
            price
            minutes
          }
        }
        order
        id
        diseases {
          title
        }
        image {
          url
          file {
            ...Image
          }
        }
        forename
        surname
        title
        pricings {
          price
          minutes
        }
      }
    }
  }
`;

export default Therapists;
