import { List as JList } from 'juhuui';
import React, { ReactNode } from 'react';

export interface Item {
  id: number;
  title: string;
  link?: string;
  type?: string;
  lineStyle?: string;
  indent?: boolean;
  outerLine?: boolean;
  [key: string]: any;
}

interface ListYBorderProps {
  children: ReactNode;
  link: boolean;
  type: string;
  lineStyle: string;
  outerLine: boolean;
}

const List = JList.with({}, ['styleType']);

function ListYBorder({
  link = true,
  type = 'ol',
  outerLine = true,
  lineStyle = '1px solid tom.line',
  children,
  css,
  ...props
}: ListYBorderProps) {
  return (
    <List
      as={type}
      className="list-y-border"
      css={{
        fontSize: ['md'],
        listStylePosition: ['inside', undefined, 'outside'],
        m: '0',
        '& > *': {
          borderBottom: lineStyle,
        },
        '& > *:first-child': {
          borderTop: outerLine ? lineStyle : 'none',
        },
        '& > *:last-child': {
          borderBottom: outerLine ? lineStyle : 'none',
        },
        ...css,
      }}
      styleType="none"
      {...props}
    >
      {children}
    </List>
  );
}

export default ListYBorder;
