import { graphql, useStaticQuery } from 'gatsby';
import {
  Link as GLink,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { Box, Flex, Icon, Tag } from 'juhuui';
import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';

import { Button } from '../components/button';
import Line from '../components/line';
import {
  Grid,
  HeadingLg,
  HeadingSm,
  LinkFrame,
  TextLg,
  TextSm,
} from '../styles';
import { Method, Specialty } from '../types';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { useStore } from '../zustand';
import ListYBorder, { Item } from './listYBorder';
import { ModalContent } from './modalContent';

interface Item {
  content: any[];
  image: any | undefined;
  meta: any;
  methods: any;
  specialties: any;
  forename: string;
  surname: string;
  title: string;
  subtitle: string;
  bookingLink: string;
  educations: any;
}

function ModalTeam({ fieldItemId, ...field }) {
  const { t } = useTranslation();
  const { language } = useI18next();

  const data = useStaticQuery(query);
  const { therapists, fact } = data.strapi || {};

  const item = useMemo(() => {
    const therapist = therapists.filter((therapist) => {
      return (
        (field.forename === therapist.forename &&
          field.surname === therapist.surname) ||
        fieldItemId === therapist.id
      );
    });
    return therapist.length > 0 ? therapist[0] : [];
  }, [fieldItemId]);

  const {
    title,
    content,
    methods,
    forename,
    surname,
    bookingLink,
    specialties,
    subtitle,
    educations,
    methodsActive,
    educationsActive,
    specialtiesActive,
  } = getLocalizationObj(item, language) || {};

  const setModalLevel2Visible = useStore(
    (state) => state.setModalLevel2Visible
  );
  const [setModalLevel1Data, setModalLevel2Data] = useStore(
    (state) => [state.setModalLevel1Data, state.setModalLevel2Data],
    shallow
  );
  const modalLevel2Visible = useStore((state) => state.modalLevel2Visible);

  const changeModal = ({ id, ...rest }) => {
    setModalLevel2Visible(false);

    modalLevel2Visible
      ? setModalLevel1Data({ name: 'method', id, ...rest })
      : setModalLevel2Data({ name: 'method', id, ...rest });
  };

  if (!forename || !surname) return null;

  return (
    <>
      <Grid
        className="modal-header"
        css={{
          alignItems: 'center',
          bg: 'inherit',
          column: ['1/span 12', undefined, '1/span 9'],
          pos: 'sticky',
          zIndex: '1',
          top: '0',
        }}
        fw={{ columns: [12, undefined, 9] }}
      >
        <TextSm
          css={{
            bg: 'inherit',
            column: ['1/span 12', undefined, '1/span 6'],
            pb: ['4'],
            pt: ['5', undefined, '6'],
            '& hover': { bg: 'blue' },
          }}
        >
          {`Team / ${forename} ${surname}`}
        </TextSm>
        <Flex
          css={{
            column: ['11/span 2', undefined, '7/span 3'],
            display: ['none', undefined, 'flex'],
            fontSize: ['sm'],
            justify: 'flex-end',
          }}
        >
          {bookingLink && (
            <Button
              alt={`${t('book an appointment online')} ${forename} ${t(
                'at_bei'
              )} Mikro Makro`}
              href={bookingLink}
              target="_blank"
              text={`${t('appointment booking')} ${t('at_bei')} ${forename}`}
            />
          )}
        </Flex>
        <Line
          css={{
            column: ['1/span 12', undefined, '1/span 9'],
            my: '0',
          }}
        />
      </Grid>
      <Box
        css={{
          column: ['1/span 12', undefined, '1/span 5'],
          pt: ['6'],
          pb: ['12', undefined, '16'],
        }}
      >
        <HeadingLg as="h2">{`${forename} ${surname}`}</HeadingLg>
        <HeadingLg
          as="h3"
          classNames="text-textSecondary"
          // css={{
          //   color: 'tom.textSecondary',
          // }}
        >
          {title}
        </HeadingLg>
      </Box>
      <Grid
        css={{
          column: ['1/span 12', undefined, '1/span 6'],
        }}
        fw={{ columns: [12, undefined, 6] }}
      >
        <TextLg
          as="blockquote"
          css={{
            column: ['1/span 12', undefined, '1/span 5'],
            // c: 'tom.textHighlight',
            pb: [12],
          }}
        >
          {subtitle}
        </TextLg>
        <Box css={{ column: ['1/span 12', undefined, '1/span 5'] }}>
          <ModalContent content={content} />
        </Box>
      </Grid>
      <Box
        css={{
          column: ['1/span 12', undefined, '7/span 3'],
          pt: ['16', undefined, '0px'],
          pb: [6],
          d: ['none', undefined, 'block'],
        }}
      >
        {methods && methods.length > 0 && methodsActive && (
          <Box
            css={{
              pb: [6, undefined, 16],
            }}
          >
            <HeadingSm as="h3" classNames="pb-3">
              {t('areas of expertise')}
            </HeadingSm>
            <ListYBorder
              css={{ border: '1px solid tom.line' }}
              outerLine={false}
            >
              {methods.map((method: Method) => {
                const { id, title } = method;
                return (
                  <LinkFrame
                    key={id}
                    css={{
                      align: 'center',
                      cursor: 'pointer',
                      d: 'flex',
                      justify: 'space-between',
                      p: ['1', undefined, '2'],
                    }}
                    icon="hover"
                    text="hover"
                    onClick={() => changeModal(method)}
                  >
                    <TextSm className="link-text" css={{ maxW: '80%' }}>
                      {title}
                    </TextSm>
                    <Icon
                      className="link-icon"
                      css={{
                        size: ['6'],
                        flexShrink: '0',
                      }}
                      name="more"
                    />
                  </LinkFrame>
                );
              })}
            </ListYBorder>
          </Box>
        )}

        {educations && educations.length > 0 && educationsActive && (
          <Box css={{ pb: [6, undefined, 16] }}>
            {educations.map((education: any) => {
              const { name } = education;
              return (
                <Tag
                  key={name}
                  css={{
                    px: ['3'],
                    py: ['2'],
                    mb: ['-1px'],
                    mr: ['-1px'],
                    c: 'tom.textSecondary',
                    bg: 'none',
                    border: '1px solid tom.line',
                    rounded: 'none',
                  }}
                  merge={TextSm}
                >
                  {name}
                </Tag>
              );
            })}
          </Box>
        )}
        {specialties && specialties.length > 0 && specialtiesActive && (
          <Box css={{ color: 'tom.textSecondary', pb: [6, undefined, 10] }}>
            <TextSm css={{ pb: ['3'] }}>{t('specialized at')}</TextSm>
            <ListYBorder>
              {specialties.map((specialty: Specialty) => {
                const { id, title } = specialty;
                return (
                  <Flex
                    key={id}
                    css={{
                      align: 'center',
                      justify: 'space-between',
                      py: ['1', undefined, '2'],
                    }}
                  >
                    <TextSm css={{ maxW: '80%' }}>{title}</TextSm>
                  </Flex>
                );
              })}
            </ListYBorder>
          </Box>
        )}
      </Box>
    </>
  );
}

const query = graphql`
  query ModalTeamQuery {
    strapi {
      fact {
        phone
      }
      therapists {
        localizations {
          id
          educations {
            name
          }
          methodsActive
          specialtiesActive
          educationsActive
          content {
            ... on STRAPI_ComponentArticleImage {
              id
              image {
                url
                file {
                  ...Image
                }
              }
            }
            ... on STRAPI_ComponentArticleText {
              id
              text
            }
            ... on STRAPI_ComponentTableTable {
              id
              items {
                description
                title
                id
              }
              title
            }
          }
          image {
            url
            file {
              ...Image
            }
          }
          meta {
            description
            title
          }
          forename
          surname
          title
          bookingLink
          methods {
            id
            title
            link
          }
          specialties {
            id
            title
          }
          subtitle
        }
        id
        educations {
          name
        }
        methodsActive
        specialtiesActive
        educationsActive
        content {
          ... on STRAPI_ComponentArticleImage {
            id
            image {
              url
              file {
                ...Image
              }
            }
          }
          ... on STRAPI_ComponentArticleText {
            id
            text
          }
          ... on STRAPI_ComponentTableTable {
            id
            items {
              description
              title
              id
            }
            title
          }
        }
        image {
          url
          file {
            ...Image
          }
        }
        meta {
          description
          title
        }
        forename
        surname
        title
        bookingLink
        methods {
          id
          title
          link
        }
        specialties {
          id
          title
        }
        subtitle
      }
    }
  }
`;

export default ModalTeam;
