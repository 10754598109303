import { Box, Flex, Icon } from 'juhuui';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import { ModalContentItem } from './modalContentItem';

export const ModalContent = memo(({ content = [] }) => {
  return content?.map((item, index) => {
    return (
      <ModalContentItem
        key={`${item.__typename}${item.id}` || index}
        content={content}
        index={index}
        item={item}
      />
    );
  });
});
