import { AspectRatioBox, Box, Flex, Icon } from 'juhuui';
import React from 'react';

import { TextSm } from '../styles';
import { Disease } from '../types';
import Image, { ImageType } from './image';
import { Pricing } from './pricings';

export interface TherapistType {
  id: number;
  diseases?: Disease[];
  image?: { file: ImageType };
  name?: string;
  title?: string;
}

interface TherapistProps {
  item: TherapistType;
}

function Therapist({
  item,
  portrait = true,
  css,
  pricing,
  srcsetWidthDesktop,
  srcsetWidthMobile,
  ...props
}: TherapistProps) {
  const { forename, surname, title, image, pricings } = item;

  return (
    <Flex
      className="therapist"
      css={{
        direction: 'column',
        justify: 'flex-start',
        pb: ['6', undefined, 12],
        cursor: 'pointer',
        '& img': { transition: 'transform 0.5s ease !important' },
        '&:hover img': { transform: 'scale(1.05)' },
        '& svg': { transition: 'transform 0.25s ease !important' },
        '&:hover svg': { transform: 'translateX(-0.2rem)' },
        '& .link-text': { transition: 'transform 0.25s ease' },
        '& .link-icon g': { transition: 'all 0.25s ease' },
        '&:hover .link-icon g': {
          stroke: 'tom.textHighlight',
          color: 'tom.textHighlight',
        },
        '&:hover .link-icon path': { stroke: 'tom.textHighlight' },
        ...css,
      }}
      {...props}
    >
      <AspectRatioBox className="aspect" ratio={portrait ? 3 / 4 : 4 / 3}>
        <Image
          absolute
          alt={image?.alternativeText}
          css={{ mb: ['2'], rounded: 'sm' }}
          image={image?.file}
          srcsetWidthDesktop={srcsetWidthDesktop}
          srcsetWidthMobile={srcsetWidthMobile}
        />
      </AspectRatioBox>
      <Flex
        css={{
          direction: 'column',
          pt: [2, undefined, 3],
        }}
      >
        <Flex
          css={{
            justify: 'space-between',
            w: 'full',
          }}
        >
          <Box>
            <TextSm>{`${forename} ${surname}`}</TextSm>
            <TextSm css={{ color: 'tom.textSecondary' }}>{title}</TextSm>
          </Box>
          <Icon
            className="link-icon"
            css={{
              ml: ['4'],
              size: ['6'],
              flexShrink: '0',
            }}
            name="more"
          />
        </Flex>
        {pricing && <Pricing pricings={pricings} />}
      </Flex>
    </Flex>
  );
}

export default Therapist;
