import { Box, Divider, Flex } from 'juhuui';
import React, { Fragment } from 'react';

import { LineVertical, TextSm } from '../styles';

export const Pricing = ({ pricings = [] }) => {
  return (
    <Flex
      css={{
        justify: 'space-between',
        pt: [5, undefined, 5],
      }}
    >
      {pricings
        .sort((first: any, second: any) => first.minutes - second.minutes)
        .map((pricings, index, { length }) => {
          const { price, minutes } = pricings;
          const last = index === length - 1;
          const first = index === 0;
          const onlyTwo = length === 2;

          return (
            <Fragment key={`${price}${minutes}`}>
              {index !== 0 && (
                <LineVertical
                  css={{
                    flex: '0',
                    // m: 0,
                    // mr: 0,
                    // ml: 0,
                    // borderColor: 'tom.line',
                  }}
                  orientation="vertical"
                />
              )}
              <Flex
                css={{
                  justify:
                    last && !onlyTwo
                      ? 'flex-end'
                      : first
                      ? 'flex-start'
                      : 'center',
                  flex: '1 0',
                }}
              >
                <Box css={{}}>
                  <TextSm>{`${minutes} Min.`}</TextSm>
                  <TextSm
                    css={{ c: 'tom.textHighlight' }}
                  >{`${price} €`}</TextSm>
                </Box>
              </Flex>
              {length === 2 && index === length - 1 && (
                <>
                  <LineVertical
                    css={{
                      flex: '0',
                      // m: 0,
                      // mr: 0,
                      // ml: 0,
                      // borderColor: 'tom.line',
                    }}
                    orientation="vertical"
                  />
                  <Box css={{ flex: '1 0' }} />
                </>
              )}
            </Fragment>
          );
        })}
    </Flex>
  );
};
