import { graphql, useStaticQuery } from 'gatsby';
import {
  Link as GLink,
  Trans,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { Box, Flex, Icon } from 'juhuui';
import React, { memo, useMemo } from 'react';

import { Button } from '../components/button';
import Image from '../components/image';
import Line from '../components/line';
import { Grid, HeadingLg, TextSm } from '../styles';
import { getLocalizationObj } from '../utilities/getLocalizationObj';
import { ModalContent } from './modalContent';
import Therapists from './therapists';

interface Item {
  content: any[];
  cover: any | undefined;
  teaser: string;
  therapists: any;
  title: string;
}

const ModalServiceContent = memo((field) => {
  const { fieldName, fieldItemId } = field;

  const { t } = useTranslation();
  const { language } = useI18next();

  const data = useStaticQuery(query);
  const { methods, disciplines, fact } = data.strapi || {};
  const { phone, bookingLink } = fact || {};

  const item = useMemo(() => {
    if (fieldName === 'method') {
      const method = methods.filter((method) => {
        const localeMethod = getLocalizationObj(method, language);

        if (!localeMethod) {
          return false;
        }

        return localeMethod.id === fieldItemId;
      });
      // method.length > 0 && setItem(method[0]);
      return method.length > 0 ? method[0] : [];
    }
    if (fieldName === 'competency') {
      const competency = disciplines.filter((competency) => {
        const localeCompetency = getLocalizationObj(competency, language);

        if (!localeCompetency) {
          return false;
        }

        return localeCompetency.id === fieldItemId;
      });
      return competency.length > 0 ? competency[0] : [];
    }
    return [];
  }, [fieldItemId, fieldName]);

  const { title, cover, content, therapists } =
    getLocalizationObj(item, language) || {};

  return (
    <>
      <Grid
        className="modal-header"
        css={{
          alignItems: 'center',
          bg: 'inherit',
          column: ['1/span 12', undefined, '1/span 9'],
          pos: 'sticky',
          top: '0',
          zIndex: '1',
        }}
        fw={{ columns: [12, undefined, 9] }}
      >
        <TextSm
          css={{
            bg: 'inherit',
            column: ['1/span 12', undefined, '1/span 6'],
            pb: ['4'],
            pt: ['5', undefined, '6'],
          }}
        >
          {`${
            fieldName === 'competency' ? t('disziplines') : t('methods')
          } / ${title}`}
        </TextSm>
        <Flex
          css={{
            column: ['11/span 2', undefined, '7/span 3'],
            display: ['none', undefined, 'flex'],
            fontSize: ['sm'],
            justify: 'flex-end',
          }}
        >
          {bookingLink && (
            <Button
              alt={t('book an appointment online at Mikro Makro')}
              href={bookingLink}
              rel="noopener"
              target="_blank"
              text={t('appointment booking')}
            />
          )}
        </Flex>
        <Line
          css={{
            column: ['1/span 12', undefined, '1/span 9'],
            my: '0',
          }}
        />
      </Grid>
      <HeadingLg
        as="h2"
        classNames="col-span-12 lg:col-span-5 pt-6 pb-16 lg:pb-24"
        // css={{
        //   column: ['1/span 12', undefined, '1/span 5'],
        //   pt: ['6'],
        //   pb: ['16', undefined, '24'],
        // }}
      >
        {title}
      </HeadingLg>
      <Grid
        css={{ column: ['1/span 12', undefined, '1/span 6'] }}
        fw={{ columns: [12, undefined, 6] }}
      >
        <Image
          css={{
            py: ['4'],
            column: ['1/span 12', undefined, '1/span 5'],
          }}
          image={cover && cover.file}
        />
        <Box css={{ column: ['1/span 12', undefined, '1/span 5'] }}>
          <ModalContent content={content} />
        </Box>
      </Grid>
      <Box css={{ column: ['1/span 12', undefined, '7/span 3'] }}>
        <Therapists portrait={false} therapists={therapists} />
      </Box>
    </>
  );
});

const query = graphql`
  query ModalServicesQuery {
    strapi {
      fact {
        phone
        bookingLink
      }
      methods {
        localizations {
          id
          cover {
            url
            file {
              ...Image
            }
          }
          content {
            ... on STRAPI_ComponentArticleImage {
              id
              image {
                url
                file {
                  ...Image
                }
              }
            }
            ... on STRAPI_ComponentArticleText {
              id
              text
            }
          }
          meta {
            description
            title
          }
          title
          teaser
          therapists {
            id
          }
        }
        id
        cover {
          url
          file {
            ...Image
          }
        }
        content {
          ... on STRAPI_ComponentArticleImage {
            id
            image {
              url
              file {
                ...Image
              }
            }
          }
          ... on STRAPI_ComponentArticleText {
            id
            text
          }
        }
        meta {
          description
          title
        }
        title
        teaser
        therapists {
          id
        }
      }
      disciplines {
        localizations {
          id
          cover {
            url
            file {
              ...Image
            }
          }
          meta {
            description
            title
          }
          teaser
          title
          therapists {
            id
          }
          content {
            ... on STRAPI_ComponentArticleImage {
              id
              image {
                url
                file {
                  ...Image
                }
              }
            }
            ... on STRAPI_ComponentArticleText {
              id
              text
            }
          }
        }
        id
        cover {
          url
          file {
            ...Image
          }
        }
        meta {
          description
          title
        }
        teaser
        title
        therapists {
          id
        }
        content {
          ... on STRAPI_ComponentArticleImage {
            id
            image {
              url
              file {
                ...Image
              }
            }
          }
          ... on STRAPI_ComponentArticleText {
            id
            text
          }
        }
      }
    }
  }
`;

export default ModalServiceContent;
