// @ts-nocheck

import { Box, Flex, Icon } from 'juhuui';
import React, { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';

import Image from '../components/image';
import {
    DescriptionList, DescriptionListDescription, DescriptionListTitle, Grid, HeadingMd, LinkFrame,
    PADDING_RIGHT_OVERLAY_CONTAINER
} from '../styles';
import Accordion from './accordion';
import Markdown from './markdown';
import Therapists from './therapists';

export const ModalContentItem = memo(({ item, content, index }: any) => {
  if (!item) return null;

  const returnItem: any = useMemo(() => {
    return {
      STRAPI_ComponentArticleText: (
        <Markdown
          css={{
            column: '1/span 7',
            pb: [12],
            '&:last-child': {
              pb: '0px',
            },
          }}
        >
          {item.text}
        </Markdown>
      ),
      STRAPI_ComponentTableTable: content[index - 1] && (
        <Accordion
          css={{
            mt:
              // eslint-disable-next-line no-underscore-dangle
              content[index - 1].__typename !== 'STRAPI_ComponentTableTable'
                ? [4, undefined, 6]
                : '',
            '&': {
              borderTop: '1px solid tom.line',
              borderBottom:
                // eslint-disable-next-line no-underscore-dangle
                content[index - 1].__typename ===
                  'STRAPI_ComponentTableTable' && index + 1 === length
                  ? '1px solid tom.line'
                  : '',
            },
            whiteSpace: 'pre-wrap',
          }}
          header={(open) => (
            <LinkFrame
              css={{
                align: 'center',
                d: 'flex',
                justify: 'space-between',
                py: ['4'],
              }}
              icon="hover"
            >
              <HeadingMd
                as="h4"
                classNames={`link-text ${
                  open ? 'text-textHighlight' : ''
                } m-0 p-0`}
                // css={{
                //   c: open ? 'tom.textHighlight' : '',
                //   m: '0',
                //   p: '0',
                // }}
              >
                {item.title}
              </HeadingMd>
              <Icon
                className="link-icon"
                css={{
                  size: ['6'],
                  flexShrink: '0',
                }}
                name={open ? 'close' : 'open'}
              />
            </LinkFrame>
          )}
          indent={false}
          // openDefault={index === 0}
        >
          <DescriptionList
            css={{
              d: 'grid',
              pt: [2, undefined, 10],
            }}
            // maxContent:"auto"
          >
            {item.items?.length > 0 &&
              item.items.map((listItem: any, index: number) => (
                <Fragment key={index}>
                  <DescriptionListTitle css={{ gridColumnStart: '1' }}>
                    {listItem.title}
                  </DescriptionListTitle>
                  <DescriptionListDescription css={{ gridColumnStart: '2' }}>
                    {listItem.description}
                  </DescriptionListDescription>
                </Fragment>
              ))}
          </DescriptionList>
        </Accordion>
      ),
      STRAPI_ComponentArticleImage: (
        <Image
          key={index}
          css={{
            mb: ['8'],
            '&:last-child': {
              mb: '0px',
            },
          }}
          image={item?.image?.file}
          srcsetWidthDesktop="60vw"
        />
      ),
      // eslint-disable-next-line no-underscore-dangle
    }[item.__typename] as any;
  }, [item, content, index]);

  return returnItem;
});
