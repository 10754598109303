import { Box } from 'juhuui';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';

interface Props {
  width: number;
  header: (item: boolean) => ReactElement;
  openDefault: boolean;
  indent: boolean;
  children: ReactNode;
  props: string | number | string[] | number[];
}

function Accordion({
  width,
  header,
  indent = true,
  openDefault = false,
  isActive,
  children,
  onlySingleActive = false,
  setActive,
  index,
  ...props
}: Props) {
  const [open, setOpen] = useState(openDefault);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const ref = useRef<HTMLDivElement>();

  const calcContentMaxHeight = () => {
    if (ref.current) {
      setContentMaxHeight(ref.current.scrollHeight);
    }
    // ref.current.scrollHeight && setContentMaxHeight(ref.current.scrollHeight);
  };

  onlySingleActive &&
    useEffect(() => {
      if (isActive) setOpen(true);
      if (!isActive) setOpen(false);
    }, [isActive]);

  useEffect(() => {
    calcContentMaxHeight();
  }, [ref, contentMaxHeight, width]);

  const styleProps = useSpring({
    opacity: open ? 1 : 0,
    maxHeight: open ? `${contentMaxHeight}px` : '0px',
  });

  const changeState = () => {
    !onlySingleActive ? setOpen((state) => !state) : setActive(index);
  };

  return (
    <Box
      aria-hidden={!open}
      className="accordion"
      css={{ pl: indent && ['5'], pr: ['2'], ...props.css }}
      {...props}
    >
      <Box css={{ cursor: 'pointer' }} onClick={changeState}>
        {header && header(open)}
      </Box>
      <animated.div
        ref={ref}
        style={{
          overflow: 'hidden',
          ...styleProps,
        }}
      >
        {children}
      </animated.div>
    </Box>
  );
}

export default Accordion;
