import { Box as BoxJ, Icon } from 'juhuui';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { a, useChain, useSpring } from 'react-spring';
import shallow from 'zustand/shallow';

import { useScrollLock } from '../hooks/useScrollLock';
import { grid12 } from '../styles';
import { useStore } from '../zustand';
import ModalServicesContent from './modalServices';
import ModalTeamContent from './modalTeam';

const ModalMain = BoxJ.with({
  pos: 'fixed',
  right: '0',
  top: '0',
  '-webkit-overflow-scrolling': 'touch',
  zIndex: '2',
  bottom: '0',
  left: '0',
  overflow: 'scroll',
  // overflowX: 'hidden',
  overscrollBehavior: 'contain',
});

const AnimatedMove = BoxJ.as(a.div).with({
  // d: 'flex',
  pointerEvents: 'auto',
  willChange: 'transform',
  column: ['1/span 12', undefined, '3/span 10'],
  // mr: ['initial', undefined, '-40vw'],
  pl: ['initial', undefined, 'calc((100% - 8 * var(--gutter-width) ) / 18)'],
  bg: 'tom.bgBright',
  // pr: ['initial', undefined, 'calc(var(--px) + 40vw)'],
  pr: ['initial', undefined, 'var(--px)'],
  cursor: 'default',
  // outline: '1px solid red',
});

const AnimatedMoveBGRight = BoxJ.as(a.div).with({
  pos: 'fixed',
  right: '-5vw',
  top: 0,
  bottom: 0,
  w: '30vw',
  bg: 'tom.bgBright',
  overflowX: 'hidden',
});

const AnimatedBackground = BoxJ.as(a.div).with({
  cursor: 'pointer',
  willChange: 'background-color',
});

const AnimatedGrid = BoxJ.as(a.div).with({
  d: 'grid',
  // minHeight: '100vh',
  templateColumns: [
    'repeat(12, minmax(0, 1fr))',
    undefined,
    'repeat(9, minmax(0, 1fr))',
  ],
  gap: '0 var(--gutter-width)',
  bg: 'tom.bgBright',
  h: 'full',
  minW: 'full',
  pb: ['40'],
  // w: 'full',
  willChange: 'opacity',
});

const AnimatedClose = BoxJ.as(a.span).with({
  align: 'center',
  cursor: 'pointer',
  d: 'flex',
  bg: 'transparent',
  left: ['', undefined, '0'],
  pl: ['4'],
  pointerEvents: ['', undefined, 'none'],
  pos: 'fixed',
  w: ['initial', undefined, '10vw'],
  right: ['var(--px)', undefined, ''],
  top: [3, 3, '4'],
  willChange: 'opacity',
  zIndex: '3',
});

const Box = BoxJ.with();

export const Modal = ({ level = 1, team }: any) => {
  const [hidden, setHidden] = useState(true);
  const LEVEL_1 = level === 1;

  // set scroll lock
  const scrollRef = useRef();
  const [setScrollLock, ref] = useScrollLock(false);

  const hoverActive = useRef(false);

  const [visible, setVisibility] = useStore(
    (state) =>
      LEVEL_1
        ? [state.modalLevel1Visible, state.setModalLevel1Visible]
        : [state.modalLevel2Visible, state.setModalLevel2Visible],
    shallow
  );

  useEffect(() => {
    visible && scrollRef?.current?.scrollTo(0, 0);
    visible && setHidden(false);
  }, [visible]);

  const {
    name: fieldName = LEVEL_1 ? 'method' : 'team',
    id: fieldId,
    ...rest
  } = useStore((state) =>
    LEVEL_1 ? state.modalLevel1Data : state.modalLevel2Data
  );
  const level2Visible = LEVEL_1
    ? useStore((state) => state.modalLevel2Visible)
    : false;

  const services = fieldName === 'method' || fieldName === 'competency';

  useEffect(() => {
    if (!scrollRef) return;

    scrollRef.current && (ref.current = scrollRef.current);
    scrollRef.current && setScrollLock(visible);
  }, [visible, level2Visible]);

  const inverted = fieldName === 'method';
  // const services = fieldName === 'method' || fieldName === 'competency';
  const isTeam = team;

  /**
   * Animation
   */
  const moveRef = useRef();
  const [styleMoveProps, setStyleMoveProps] = useSpring(
    {
      from: { x: '140%' },
      x: visible ? (level2Visible && LEVEL_1 ? '-20%' : '-1%') : '140%',
      config: { mass: 1, tension: 350, friction: 40 },
      ref: moveRef,
    },
    [visible, level2Visible]
  );

  const moveExtraRightRef = useRef();
  const [styleMoveExtraRightProps] = useSpring(
    {
      from: { x: '100%' },
      x: visible ? '0%' : '100%',
      config: { mass: 1, tension: 350, friction: 30 },
      ref: moveExtraRightRef,
    },
    [visible, level2Visible]
  );

  const backgroundColor = useMemo(() => {
    return inverted
      ? 'rgba(33, 36, 32, 0.9)'
      : isTeam
      ? 'rgba(135, 125, 98, 0.9'
      : 'rgba(239, 239, 234, 0.9)';
  }, [inverted, isTeam]);

  const backgroundColorHidden = useMemo(() => {
    return inverted
      ? 'rgba(33, 36, 32, 0)'
      : isTeam
      ? 'rgba(135, 125, 98, 0'
      : 'rgba(239, 239, 234, 0)';
  }, [inverted, isTeam]);

  const opacityRef = useRef();
  const styleOpacityProps = useSpring({
    from: {
      opacity: 0,
    },
    opacity: visible ? 1 : 0,
    config: { mass: 1, tension: 170, friction: 30 },
    ref: opacityRef,
  });

  const backgroundRef = useRef();
  const styleBackgroundProps = useSpring({
    from: {
      backgroundColor,
    },
    backgroundColor: visible ? backgroundColor : backgroundColorHidden,
    config: { mass: 2, tension: 110, friction: 30 },
    ref: backgroundRef,
    onRest: () => {
      !visible && setHidden(true);
    },
  });

  const gridRef = useRef();
  const styleGridProps = useSpring({
    from: { opacity: 0 },
    opacity: visible ? 1 : 0,
    config: { mass: 5, tension: 110, friction: 30 },
    ref: gridRef,
    onStart: () => {
      visible && (hoverActive.current = true);
    },
  });

  useChain(
    visible
      ? [backgroundRef, moveExtraRightRef, moveRef, opacityRef, gridRef]
      : [opacityRef, gridRef, moveRef, moveExtraRightRef, backgroundRef],
    visible ? [0, 0.05, 0.1, 0.5, 0.6] : [0, 0.05, 0.3, 0.35, 0.6]
  );
  /**
   * Animation end
   */

  const changeVisibility = () => {
    hoverActive.current = false;
    setStyleMoveProps({
      config: { mass: 1, tension: 350, friction: 40 },
    });
    setVisibility(false);
  };

  const changeHoverState = (x, config) => {
    hoverActive.current &&
      setStyleMoveProps({
        x,
        config,
      });
  };

  // change URL
  // useEffect(() => {
  //   const type = isTeam ? 'team' : inverted ? 'methoden' : 'disziplinen';

  //   visible && window.history.pushState({ page: type }, type, `/${type}/open`);
  // }, [visible]);

  const Content = useMemo(() => {
    return services ? (
      <ModalServicesContent
        fieldItemId={fieldId}
        fieldName={fieldName}
        {...rest}
      />
    ) : (
      <ModalTeamContent fieldItemId={fieldId} {...rest} />
    );
  }, [services, fieldId, fieldName]);

  return (
    <ModalMain
      ref={scrollRef}
      aria-hidden={!visible}
      className="modal"
      css={{
        visibility: hidden ? 'hidden' : 'visible',
        pointerEvents: visible ? 'auto' : 'none',
      }}
    >
      <AnimatedBackground
        ref={backgroundRef}
        css={{
          // pointerEvents: visible ? 'auto' : 'none',
          pointerEvents: 'none',
        }}
        style={styleBackgroundProps}
      >
        <Box
          className={grid12}
          css={{
            pointerEvents: visible ? 'auto' : 'none',
            maxW: 'var(--max-width)',
            pl: ['0px', undefined, 'var(--px)'],
            pos: 'relative',
          }}
        >
          <AnimatedClose
            ref={opacityRef}
            css={{
              pointerEvents: visible ? 'auto' : 'none',
            }}
            style={styleOpacityProps}
            onClick={changeVisibility}
          >
            <Icon
              css={{
                '& path': {
                  stroke: [
                    'tom.text',
                    undefined,
                    inverted ? 'tom.textInverted' : 'tom.text',
                  ],
                },
                size: ['8'],
                flexShrink: '0',
              }}
              name="x"
            />
            <Box
              css={{
                c: inverted ? 'tom.textInverted' : 'tom.text',
                d: ['none', undefined, 'initial'],
                pl: ['2'],
              }}
            >
              Zurück
            </Box>
          </AnimatedClose>
          <Box
            css={{
              column: '1/span 2',
              ml: '-10vw',
              mr: '-3vw',
              pointerEvents: visible ? 'auto' : 'none',
            }}
            onClick={changeVisibility}
            onMouseEnter={() =>
              changeHoverState('0%', {
                mass: 1,
                tension: 210,
                friction: 30,
              })
            }
            onMouseLeave={() => {
              changeHoverState('-1%', {
                mass: 4,
                tension: 100,
                friction: 30,
              });
            }}
          />
          <AnimatedMoveBGRight
            ref={moveExtraRightRef}
            style={styleMoveExtraRightProps}
          />
          <AnimatedMove
            ref={moveRef}
            className="modal-main"
            style={styleMoveProps}
          >
            <Box
              css={{
                minHeight: '100vh',
                // pl: ['0px', undefined, '0px'],
                // pos: 'relative',
                px: ['var(--px)', undefined, '0px'],
                // pos: 'relative',
                // zIndex: 'unset',
              }}
            >
              <AnimatedGrid
                ref={gridRef}
                css={{
                  pointerEvents: visible ? 'auto' : 'none',
                  // pos: 'relative',
                  // zIndex: 'unset',
                }}
                style={styleGridProps}
              >
                {Content}
              </AnimatedGrid>
            </Box>
          </AnimatedMove>
        </Box>
      </AnimatedBackground>
    </ModalMain>
  );
};

export default Modal;
